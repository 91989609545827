import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ModalActions, ModalActionType, ModalService } from 'weavix-shared/services/modal.service';
import { NetworkDeviceType, NetworkUtility } from 'components/network-indicator/network-utility';
import { debounce } from 'lodash';
import { Company } from 'weavix-shared/models/company.model';
import { Person } from 'weavix-shared/models/person.model';
import { MapWalt } from 'weavix-shared/models/weavix-map.model';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { WaltService } from 'weavix-shared/services/walt.service';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';
import { AutoUnsubscribe, getBatteryIcon } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
  selector: 'app-walt-detail',
  templateUrl: './walt-detail.component.html',
  styleUrls: ['./walt-detail.component.scss', '../map-detail-view.scss'],
})
export class WaltDetailComponent implements OnChanges {
    @Input() walt: MapWalt;
    @Output() closeOutput: EventEmitter<void> = new EventEmitter();
    loading: boolean = true;
    waltIcon = FEATURE_ICONS.radio.icon;
    locationIcon = FEATURE_ICONS.location.icon;
    networkIcon = NetworkUtility.getNetworkIcon(NetworkDeviceType.Wifi, 0);
    batteryIcon: string;
    company: Company;
    lastAssigned: string;
    version: string;
    person: Person;
    currentlyAssigned: boolean;
    canLogOut: boolean;
    private modalActions: ModalActions = {
        cancel: {
            show: true,
            textKey: 'generics.no',
        },
        submit: {
            show: true,
            textKey: 'generics.yes',
            buttonClass: 'blue-lt',
        },
    };
    debounceLogout = debounce(async () => await this.waltService.remoteLogout(this, this.walt.id), 500);
    debouncePlaySound = debounce(async () => await this.waltService.playWaltSound(this, this.walt.id), 500);

    constructor(
        private alertService: AlertService,
        private companyService: CompanyService,
        private waltService: WaltService,
        private modalService: ModalService,
        private translationService: TranslationService,
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.walt?.previousValue?.id !== changes.walt?.currentValue?.id) {
            this.loading = true;
            await this.setup();
        } else if (changes.walt?.previousValue?.person !== changes.walt?.currentValue?.person
            || changes.walt?.previousValue?.lastAssigned?.personId !== changes.walt?.currentValue?.lastAssigned?.personId
            || changes.walt?.previousValue?.wrangler !== changes.walt?.currentValue?.wrangler) {
            await this.setup();
        }
    }

    private async setup(): Promise<void> {
        if (!this.batteryIcon) this.loading = true;
        try {
            this.setBattery();
            this.setVersion();
            await this.setAssignedTo();
            await this.setCompany();
            await this.setPerson();
            await this.setCanLogOut();
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Get, 'walt.walt');
        } finally {
            this.loading = false;
        }
    }

    private setVersion() {
        this.version = this.walt?.version;
    }

    private async setAssignedTo() {
        this.lastAssigned = this.waltService.getWaltLastAssignedName(this.walt);
    }

    private async setCanLogOut() {
        if (WaltService.isCurrentlyAssigned(this.walt) && (!(!WaltService.waltActive(this.walt) || this.walt.poweredOff))) this.canLogOut = true;
        else this.canLogOut = false;
    }

    private async setPerson() {
        this.currentlyAssigned = WaltService.isCurrentlyAssigned(this.walt);
        if (this.currentlyAssigned) this.person = await this.waltService.getWaltLastAssignedPerson(this, this.walt);
    }

    private async setCompany() {
        this.company = null;
        const { companyId } = this.walt.lastAssigned;
        if (!companyId) return;
        this.company = await this.companyService.get(this, companyId);
    }

    private setBattery() {
        this.batteryIcon = getBatteryIcon(this.walt.battery * 100);
    }

    async playSound() {
        this.debouncePlaySound();
    }

    async remoteLogout() {
        const modalResult = await this.modalService.confirm('', this.translationService.getImmediate('walt.confirm-remote-logout'), this.modalActions, true);
        if (modalResult.action === ModalActionType.submit) this.debounceLogout();
    }

    handleClose() {
        this.closeOutput.emit();
    }
}
