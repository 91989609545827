<app-loading [loading]="loading" [theme]="'transparent'"></app-loading>

<div *ngIf="!loading" class="wrapper flex1 flexColumn jcFS" style="overflow-y:auto;">
    <div class="flexColumn jcC h100p w100p" *ngIf="!walt">
        <p class="no-data">{{'ERRORS.GENERICS.LOAD-DATA' | translate}}</p>
    </div>

    <ng-container *ngIf="walt">
        <div (click)="handleClose()" class="intro">
            <app-icon
                class="intro-close"
                [faIcon]="'fa fa-chevron-left'"
            ></app-icon>

            <p *ngIf="walt.id" class="intro-name">{{walt.id}}</p>
        </div>

        <div class="walt-intro flexRow jcC">
            <app-icon [icon]="waltIcon" [fontSize]="'60px'"></app-icon>
            <div class="walt-info">
                <p *ngIf="walt.id" class="name">{{walt.id}}</p>
                <div *ngIf="walt.macAddress" class="header-info">
                    <p class="header-info">{{'walt.mac' | translate }}:</p>
                    <p class="header-info-light">{{walt.macAddress}}</p>
                </div>
                <div *ngIf="walt.btAddress" class="header-info">
                    <p class="header-info">{{'walt.bt-address' | translate }}:</p>
                    <p class="header-info-light">{{walt.btAddress}}</p>
                </div>
                <div *ngIf="walt.network" class="header-info">
                    <p class="header-info">{{'walt.current' | translate }}:</p>
                    <p class="header-info-light">{{walt.network.toUpperCase()}}</p>
                </div>
            </div>

            <!-- add new Where's Walt permission-->
            <button class="blue-lt small w100p mT10 flexRow jcC" (click)="playSound()">
                <app-icon [faIcon]="'fas fa-volume-up'" [fontSize]="'20px'"></app-icon>
                <p class="pL5">{{'walt.play-sound' | translate}}</p>
            </button>

            <button class="blue-lt small w100p mT10 flexRow jcC"
                *ngIf="walt.loggedIn && canLogOut"
                (click)="remoteLogout()"
            >
                <app-icon [faIcon]="'fa fa-sign-out-alt'" [fontSize]="'20px'"></app-icon>
                <p class="pL5">{{'generics.logout' | translate}}</p>
            </button>
        </div>

        <div class="info-box" *ngIf="walt?.currentNetwork">
            <p class="info-box-title">{{'walt.network' | translate}}</p>
            <div class="info-box-content">
                <app-icon [faIcon]="walt.network === 'wifi' ? 'fas fa-wifi' : 'fas fa-signal-alt'"></app-icon>
                <p class="pL5">{{walt?.currentNetwork?.ssid}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.ip' | translate }}:</p>
                <p class="header-info-light">{{walt?.currentNetwork?.ip}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.mac' | translate }}:</p>
                <p class="header-info-light">{{walt?.currentNetwork?.mac?.toUpperCase()}}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="walt?.simOne?.iccid">
            <p class="info-box-title">{{'walt.sim-one' | translate}}</p>
            <div class="header-info">
                <p class="header-info">{{ 'walt.carrier' | translate }}:</p>
                <p class="header-info-light">{{walt?.simOne?.carrier}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.iccid' | translate }}:</p>
                <p class="header-info-light">{{walt?.simOne?.iccid}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.imei' | translate }}:</p>
                <p class="header-info-light">{{walt?.simOne?.imei}}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="walt?.simTwo?.iccid">
            <p class="info-box-title">{{'walt.sim-two' | translate}}</p>
            <div class="header-info">
                <p class="header-info">{{ 'walt.carrier' | translate }}:</p>
                <p class="header-info-light">{{walt?.simTwo?.carrier}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.iccid' | translate }}:</p>
                <p class="header-info-light">{{walt?.simTwo?.iccid}}</p>
            </div>
            <div class="header-info">
                <p class="header-info">{{ 'walt.imei' | translate }}:</p>
                <p class="header-info-light">{{walt?.simTwo?.imei}}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="walt.wrangler?.id">
            <p class="info-box-title">{{'walt.wrangler' | translate}}</p>
            <div class="info-box-content" style="flex-direction:column;">
                <p class="pL5">{{walt.wrangler.id}}</p>
                <p class="pL5">{{'walt.slot' | translate: { id: walt.wrangler.slot } }}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="batteryIcon">
            <p class="info-box-title">{{'walt.battery' | translate}}</p>
            <div class="info-box-content">
                <app-icon [faIcon]="batteryIcon" [fontSize]="'30px'"></app-icon>
                <p class="pL5">{{walt.battery | percent}}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="version">
            <p class="info-box-title">{{'walt.version' | translate}}</p>
            <div class="info-box-content">
                <p class="pL5">{{version}}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="currentlyAssigned && person.fullName">
            <p class="info-box-title">{{'walt.assigned-to' | translate}}</p>
            <div class="info-box-content">
                <app-avatar
                    [avatarInput]="{
                        height: 40,
                        width: 40,
                        img: person.avatarFile,
                        placeholder: { 'icon': 'fas fa-user', 'iconColor': 'white' }
                    }"
                    [readonly]="true"
                    class="pT10 pB5"
                ></app-avatar>
            </div>
            <div class="info-box-content">
                <p>{{person.fullName}}</p>
            </div>
        </div>

        <div *ngIf="company" class="info-box">
            <p class="info-box-title">{{'walt.company' | translate}}</p>
            <div class="info-box-content">
                <img *ngIf="company.logo" class="pT10 pB5" style="max-width: 60px;max-height: 60px;" [src]="company.logo" [alt]="company.name" [title]="company.name">
            </div>
            <div class="info-box-content">
                <p>{{ company.name }}</p>
            </div>
        </div>

        <div class="info-box" *ngIf="walt.locationUpdated">
            <p class="info-box-title">{{'walt.last-updated' | translate}}</p>
            <div class="info-box-content">
                <p *ngIf="walt.locationUpdated">{{walt.locationUpdated | timeAgo}}</p>
            </div>
        </div>
    </ng-container>
</div>

